import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ssh inspect`}</strong>{` -- print the contents of an ssh certificate`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ssh inspect <crt-file>
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ssh inspect`}</strong>{` command prints ssh certificate details in human readable
format.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`crt-file`}</inlineCode>{`
The path to an ssh certificate.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--format`}</strong>{`=`}<inlineCode parentName="p">{`format`}</inlineCode>{`
The output format for printing the introspection details.`}</p>
    <p><inlineCode parentName="p">{`format`}</inlineCode>{` is a string and must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`text`}</strong>{`: Print output in unstructured text suitable for a human to read.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`json`}</strong>{`: Print output in JSON format.`}</p>
      </li>
    </ul>
    <h2>{`Examples`}</h2>
    <p>{`Prints the contents of id_ecdsa-cert.pub:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh inspect id_ecdsa-cert.pub
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      